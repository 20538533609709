import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import axios from 'axios';
import form from 'jquery-form';
import $ from 'jquery';
import moment from 'moment';
var calibrationMeterColumns = [{
  title: '参数名称',
  dataIndex: 'name',
  key: 'name'
}, {
  title: '起始值',
  dataIndex: 'startValue',
  key: 'startValue'
}, {
  title: '结束值',
  dataIndex: 'endValue',
  key: 'endValue'
}];
var columns = [{
  title: '设备ID',
  dataIndex: 'numbering',
  width: 180,
  scopedSlots: {
    customRender: '设备ID'
  }
}, {
  title: '设备名称',
  dataIndex: 'name',
  width: 140,
  scopedSlots: {
    customRender: '设备名称'
  }
}, {
  title: '设备类型',
  dataIndex: 'typeName',
  width: 80,
  scopedSlots: {
    customRender: '设备类型'
  }
}, {
  title: '设备版本',
  dataIndex: 'version',
  width: 80,
  scopedSlots: {
    customRender: '设备版本'
  }
}, {
  title: '上级设备',
  dataIndex: 'parentEquipmentName',
  width: 100,
  scopedSlots: {
    customRender: '上级设备'
  }
}, {
  title: '说明',
  dataIndex: 'description',
  width: 140,
  scopedSlots: {
    customRender: '说明'
  }
}, {
  title: '操作',
  dataIndex: 'action',
  width: 80,
  scopedSlots: {
    customRender: 'action'
  }
}];
export default {
  data: function data() {
    var _this = this;

    var validVersion = function validVersion(rule, value, callback) {
      if (_this.equipmentForm.typeId < 4) {
        callback();
      } else {
        if (!value) {
          callback(new Error("请填写设备版本！"));
        } else {
          callback();
        }
      }
    };

    var validMeterParamtersId = function validMeterParamtersId(rule, value, callback) {
      if (_this.equipmentForm.typeId > 3) {
        if (!value) {
          callback(new Error("请填写电表解析规则id！"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return {
      moment: moment,
      searchLoading: false,
      calibrationLoading: false,
      queryDate: null,
      calibrationMeterColumns: calibrationMeterColumns,
      calibrationMeterData: [],
      activeKey: ['1'],
      expandIconPosition: 'left',
      descriptionsItemSize: 'small',
      calibrationMeterFormShow: false,
      detialTitle: '',
      placement: 'right',
      porductionDetialShow: false,
      wrapperCol: {
        span: 13
      },
      labelCol: {
        span: 5
      },
      equipmentRles: {
        typeId: [{
          required: true,
          message: '请选择设备类型！',
          trigger: 'blur'
        }],
        parentEquipmentId: [{
          required: true,
          message: '请选择上级设备！',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请填写设备名称！',
          trigger: 'blur'
        }],
        numbering: [{
          required: true,
          message: '请填写设备ID！',
          trigger: 'blur'
        }],
        num: [{
          required: true,
          message: '请填写设备编号！',
          trigger: 'blur'
        }],
        templateId: [{
          required: true,
          message: '请选择模板',
          trigger: 'blur'
        }],
        version: [{
          validator: validVersion,
          trigger: 'blur' //					}],
          //					meterParamtersId: [{
          //						validator: validMeterParamtersId,
          //						trigger: 'blur'

        }]
      },
      selectedRows: [],
      rowSelection: {
        columnWidth: 50,
        onChange: function onChange(selectedRowKeys, selectedRows) {
          _this.selectedRows = selectedRows;
        }
      },
      pagination: {
        defaultPageSize: 50,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '25', '50', '100'],
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          return _this.pageSize = pageSize;
        }
      },
      columns: columns,
      dateFormat: 'YYYY-MM-DD',
      scroll: {
        y: 660
      },
      equipmentForm: {
        id: null,
        typeId: null,
        parentEquipmentId: null,
        name: null,
        numbering: null,
        num: null,
        templateId: null,
        typeName: null,
        version: null,
        cellCount: 0,
        temperatureSensorCount: 0,
        maxPower: 0,
        capacity: 0,
        stationId: localStorage.getItem('stationId'),
        meterParamtersId: null
      },
      keyboard: false,
      equipTypeList: [],
      parentEquipmentList: [],
      search: {
        parentEquipmentId: '-1',
        stationId: localStorage.getItem('stationId'),
        name: null,
        typeId: '-1',
        length: 1000000000,
        page: 1,
        startIndex: 0
      },
      tableData3: [],
      total: 0,
      pageSize: 50,
      showTotal: true,
      equipmentFormShow: false,
      dialogTitle: '添加设备',
      operationType: 0,
      templateList: [],
      hardwareVersion: null,
      bmuHardwareVersion: null,
      pcsHardwareVersion: null,
      appVersion: null,
      flieSystemVersion: null,
      kernelVersion: null,
      BOOTVersion: null,
      pcsFPGAVersion: null,
      pcsCPU1BOOTVersion: null,
      pcsCPU2BOOTVersion: null,
      pcsCPU1APPVersion: null,
      pcsCPU2APPVersion: null,
      bmuAPPVersion: null,
      bmuBOOTVersion: null,
      productNumber: null,
      productId: null,
      loading: false,
      equipment: null,
      calibrationEnergyData: null
    };
  },
  mounted: function mounted() {
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var id = localStorage.getItem('stationId');

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    var this_ = this;
    this_.getEquipTypeList();
    this_.getParentEquipmentList();
    this_.getList();
  },
  methods: {
    calibration: function calibration(id) {
      var this_ = this;
      this_.calibrationLoading = true;
      var data = null;
      this_.calibrationEnergyData.map(function (item) {
        if (item.id == id) {
          data = {
            equipmentId: this_.equipment.id,
            stationId: localStorage.getItem('stationId'),
            equipmentType: this_.equipment.typeId,
            startRecordTime: item.startRecordTime,
            S_T_charge_electric_energyEnergy: item.startPositiveActiveEnergy,
            S_S_charge_electric_energyEnergy: item.startSharpPeriodChargeElectricEnergy,
            S_P_charge_electric_energyEnergy: item.startPeakPeriodChargeElectricEnergy,
            S_F_charge_electric_energyEnergy: item.startFlatPeriodChargeElectricEnergy,
            S_V_charge_electric_energyEnergy: item.startValleyPeriodChargeElectricEnergy,
            S_T_discharge_electric_energyEnergy: item.startReverseActiveEnergy,
            S_S_discharge_electric_energyEnergy: item.startSharpPeriodDischargeElectricEnergy,
            S_P_discharge_electric_energyEnergy: item.startPeakPeriodDischargeElectricEnergy,
            S_F_discharge_electric_energyEnergy: item.startFlatPeriodDischargeElectricEnergy,
            S_V_discharge_electric_energyEnergy: item.startValleyPeriodDischargeElectricEnergy,
            endRecordTime: item.endRecordTime,
            E_T_charge_electric_energyEnergy: item.endPositiveActiveEnergy,
            E_S_charge_electric_energyEnergy: item.endSharpPeriodChargeElectricEnergy,
            E_P_charge_electric_energyEnergy: item.endPeakPeriodChargeElectricEnergy,
            E_F_charge_electric_energyEnergy: item.endFlatPeriodChargeElectricEnergy,
            E_V_charge_electric_energyEnergy: item.endValleyPeriodChargeElectricEnergy,
            E_T_discharge_electric_energyEnergy: item.endReverseActiveEnergy,
            E_S_discharge_electric_energyEnergy: item.endSharpPeriodDischargeElectricEnergy,
            E_P_discharge_electric_energyEnergy: item.endPeakPeriodDischargeElectricEnergy,
            E_F_discharge_electric_energyEnergy: item.endFlatPeriodDischargeElectricEnergy,
            E_V_discharge_electric_energyEnergy: item.endValleyPeriodDischargeElectricEnergy,
            status: false
          };
        }
      });

      if (data == null) {
        this_.$message.error('请选择需要校准的数据！', 2, function () {});
        return;
      }

      axios({
        method: 'post',
        url: '/emind/history/data/calibration/energy/record',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.$message.success('电表数据校准成功！');
        } else {
          this_.$message.warning('电表数据校准失败！');
        }

        this_.calibrationLoading = false;
      }).catch(function (error) {
        this_.calibrationLoading = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
        }
      });
    },
    searchAbnormalData: function searchAbnormalData() {
      var this_ = this;

      if (this_.queryDate == null) {
        this_.$message.info('请先选择查询日期！', 2, function () {});
        return;
      }

      var data = {
        "equipmentId": this_.equipment.id,
        "equipmentType": this_.equipment.typeId,
        "stationId": localStorage.getItem('stationId'),
        "name": this_.equipment.numbering,
        "startTime": this_.queryDate.format('YYYY-MM-DD ') + "00:00:00",
        "endTime": this_.queryDate.format('YYYY-MM-DD ') + "23:59:59"
      };
      this_.searchLoading = true;
      axios({
        method: 'post',
        url: '/emind/history/data/calibration/energy/data',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.status === '2000') {
          this_.calibrationMeterData = [];
          this_.calibrationEnergyData = d.data.data;
          this_.calibrationEnergyData.map(function (item) {
            item.id = this_.calibrationMeterData.length;
            this_.calibrationMeterData.push({
              "id": this_.calibrationMeterData.length,
              'header': item.startRecordTime + ' ~ ' + item.endRecordTime,
              'tableDate': [{
                'name': '记录时间',
                'startValue': item.startRecordTime,
                'endValue': item.endRecordTime
              }, {
                'name': '正向有功电能-总',
                'startValue': item.startPositiveActiveEnergy / 10,
                'endValue': item.endPositiveActiveEnergy / 10
              }, {
                'name': '正向有功电能-尖',
                'startValue': item.startSharpPeriodChargeElectricEnergy / 10,
                'endValue': item.endSharpPeriodChargeElectricEnergy / 10
              }, {
                'name': '正向有功电能-峰',
                'startValue': item.startPeakPeriodChargeElectricEnergy / 10,
                'endValue': item.endPeakPeriodChargeElectricEnergy / 10
              }, {
                'name': '正向有功电能-平',
                'startValue': item.startFlatPeriodChargeElectricEnergy / 10,
                'endValue': item.endFlatPeriodChargeElectricEnergy / 10
              }, {
                'name': '正向有功电能-谷',
                'startValue': item.startValleyPeriodChargeElectricEnergy / 10,
                'endValue': item.endValleyPeriodChargeElectricEnergy / 10
              }, {
                'name': '反向有功电能-总',
                'startValue': item.startReverseActiveEnergy / 10,
                'endValue': item.endReverseActiveEnergy / 10
              }, {
                'name': '反向有功电能-尖',
                'startValue': item.startSharpPeriodDischargeElectricEnergy / 10,
                'endValue': item.endSharpPeriodDischargeElectricEnergy / 10
              }, {
                'name': '反向有功电能-峰',
                'startValue': item.startPeakPeriodDischargeElectricEnergy / 10,
                'endValue': item.endPeakPeriodDischargeElectricEnergy / 10
              }, {
                'name': '反向有功电能-平',
                'startValue': item.startFlatPeriodDischargeElectricEnergy / 10,
                'endValue': item.endFlatPeriodDischargeElectricEnergy / 10
              }, {
                'name': '反向有功电能-谷',
                'startValue': item.startValleyPeriodDischargeElectricEnergy / 10,
                'endValue': item.endValleyPeriodDischargeElectricEnergy / 10
              }]
            });
          });
        } else {
          this_.$message.warning('获取电表异常数据失败！');
        }

        this_.searchLoading = false;
      }).catch(function (error) {
        this_.searchLoading = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
        }
      });
    },
    cacheEquipments: function cacheEquipments() {
      var this_ = this;
      axios({
        method: 'post',
        url: '/emind/real/data/cache/equipments',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.$message.success('服务器更新设备缓存列表成功');
        } else {
          this_.$message.warning('服务器更新设备缓存列表失败');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
        }
      });
    },
    cancelCalibrationMeter: function cancelCalibrationMeter() {
      var this_ = this;
      this_.calibrationMeterFormShow = false;
      this_.queryDate = null;
      this_.calibrationMeterData = [];
    },
    confirmCalibrationMeter: function confirmCalibrationMeter() {
      var this_ = this;
      axios({
        method: 'put',
        url: '/emind/config/equipment/calibration/meter',
        data: this_.calibrationMeterFormShow
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.$message.success('设备标定成功');
          this_.calibrationMeterFormShow = false;
          setTimeout(function () {
            this_.cacheEquipments();
            this_.getList();
          }, 100);
        } else if (d.data.code === '401') {
          this_.$message.warning('当前账户没有标定设备的操作权限');
        } else {
          this_.calibrationMeterFormShow = false;
          this_.$message.warning('设备标定失败');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.calibrationMeterFormShow = false;
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    calibrationMeter: function calibrationMeter(record) {
      var this_ = this;
      this_.equipment = record;
      this.calibrationMeterFormShow = true;
    },
    onClose: function onClose() {
      this.porductionDetialShow = false;
    },
    cancel: function cancel() {
      this.equipmentFormShow = false;
    },
    changeTemplate: function changeTemplate() {
      var this_ = this;
      this_.templateList.map(function (item) {
        if (item.id == this_.equipmentForm.templateId) {
          this_.equipmentForm.version = item.version;
          this_.equipmentForm.meterParamtersId = item.meterParamtersId;
          this_.equipmentForm.cellCount = item.repeatCellCount;
          this_.equipmentForm.temperatureSensorCount = item.repeatTemperatureProbeCount;
          this_.equipmentForm.maxPower = item.maxPower;
          this_.equipmentForm.capacity = item.capacity;
        }
      });
    },
    getTemplateList: function getTemplateList(type) {
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/config/equipment/template/' + type
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.templateList = d.data.data;
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    changeType: function changeType() {
      this.getTemplateList(this.equipmentForm.typeId);
    },
    showConfirm: function showConfirm(row) {
      this.detialTitle = row.parentEquipmentName + "——" + row.name;
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/config/equipment/product/info/' + row.id
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code == '0' && d.data.data != null) {
          this_.type = row.typeId;
          this_.hardwareVersion = d.data.data.hardwareVersion;
          this_.bmuHardwareVersion = d.data.data.bmuHardwareVersion;
          this_.pcsHardwareVersion = d.data.data.pcsHardwareVersion;
          this_.productNumber = d.data.data.productNumber;
          this_.productId = d.data.data.num;

          if (this_.type == 2) {
            var softwareVersion = d.data.data.softwareVersion;

            if (softwareVersion != null) {
              this_.appVersion = softwareVersion.split(';')[0];
              this_.flieSystemVersion = softwareVersion.split(';')[1];
              this_.kernelVersion = softwareVersion.split(';')[2];
            }
          } else if (this_.type == 3) {
            var softwareVersion = d.data.data.softwareVersion;

            if (softwareVersion != null) {
              this_.appVersion = softwareVersion.split(';')[0];
              this_.BOOTVersion = softwareVersion.split(';')[1];
            }

            softwareVersion = d.data.data.bmuSoftwareVersion;

            if (softwareVersion != null) {
              this_.bmuAPPVersion = softwareVersion.split(';')[0];
              this_.bmuBOOTVersion = softwareVersion.split(';')[1];
            }

            softwareVersion = d.data.data.pcsSoftwareVersion;

            if (softwareVersion != null) {
              this_.pcsFPGAVersion = softwareVersion.split(';')[0];
              this_.pcsCPU1BOOTVersion = softwareVersion.split(';')[1];
              this_.pcsCPU2BOOTVersion = softwareVersion.split(';')[2];
              this_.pcsCPU1APPVersion = softwareVersion.split(';')[3];
              this_.pcsCPU2APPVersion = softwareVersion.split(';')[4];
            }
          }
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$Message.error(error.message, 2, function () {});
          return;
        }
      });
      this_.equipment = row;

      if (this_.equipment.parentEquipmentName != null && this_.equipment.parentEquipmentName.length > 0) {
        this_.paramSettingTitle = this_.equipment.parentEquipmentName + '——' + this_.equipment.name;
      } else {
        this_.paramSettingTitle = this_.equipment.name;
      }

      this.porductionDetialShow = true;
    },
    confirm: function confirm() {
      var this_ = this;
      this_.$refs['equipmentForm'].validate(function (valid) {
        if (valid) {
          this_.okFn();
        }
      });
    },
    okFn: function okFn() {
      var this_ = this;
      this_.equipTypeList.map(function (x) {
        if (x.id === this_.equipmentForm.typeId) {
          this_.equipmentForm.typeName = x.name;
        }
      });

      if (this_.operationType == 1) {
        axios({
          method: 'post',
          url: '/emind/config/equipment/creation',
          data: this_.equipmentForm
        }).then(function (d) {
          //
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code === '0') {
            this_.$message.success('设备添加成功');
            this_.operationType = 0;
            this_.equipmentFormShow = false;
            this_.equipmentForm = {
              id: null,
              typeId: null,
              parentEquipmentId: null,
              name: null,
              numbering: null,
              num: null,
              templateId: null,
              typeName: null,
              cellCount: 0,
              temperatureSensorCount: 0,
              maxPower: 0,
              capacity: 0,
              version: null
            };
            setTimeout(function () {
              this_.cacheEquipments();
              this_.getList();
            }, 100);
          } else {
            this_.$message.warning('设备添加失败');
          }
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      } else if (this_.operationType == 2) {
        axios({
          method: 'put',
          url: '/emind/config/equipment/modification',
          data: this_.equipmentForm
        }).then(function (d) {
          //
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code === '0') {
            this_.$message.success('更新设备成功');
            this_.operationType = 0;
            this_.equipmentFormShow = false;
            this_.equipmentForm = {
              id: null,
              typeId: null,
              parentEquipmentId: null,
              name: null,
              numbering: null,
              num: null,
              templateId: null,
              typeName: null,
              cellCount: 0,
              temperatureSensorCount: 0,
              maxPower: 0,
              capacity: 0,
              version: null
            };
            setTimeout(function () {
              this_.cacheEquipments();
              this_.getList();
            }, 100);
          } else {
            this_.$message.warning('更新设备失败');
          }
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      }
    },
    add: function add() {
      var this_ = this;
      this_.getParentEquipmentList();
      this_.getEquipTypeList();
      this_.dialogTitle = '添加设备';
      this_.equipmentFormShow = true;
      this_.operationType = 1;
      this_.equipmentForm = {
        id: null,
        typeId: null,
        parentEquipmentId: null,
        stationId: localStorage.getItem('stationId'),
        name: null,
        numbering: null,
        num: null,
        templateId: null,
        typeName: null,
        cellCount: 0,
        temperatureSensorCount: 0,
        maxPower: 0,
        capacity: 0,
        version: null
      };
    },
    edit: function edit() {
      var this_ = this;

      if (this_.selectedRows.length != 1) {
        this.$message.warning('您还没有选择要更新的设备，请先选择！');
        return;
      }

      this.getTemplateList(this_.selectedRows[0].typeId);
      axios({
        method: 'get',
        url: '/emind/config/equipment/get/' + this_.selectedRows[0].id
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.equipmentForm.id = d.data.data.id;
          this_.equipmentForm.typeId = d.data.data.typeId;
          this_.equipmentForm.parentEquipmentId = d.data.data.parentEquipmentId;
          this_.equipmentForm.name = d.data.data.name;
          this_.equipmentForm.numbering = d.data.data.numbering;
          this_.equipmentForm.num = d.data.data.num;
          this_.equipmentForm.stationId = d.data.data.stationId;
          this_.equipmentForm.typeName = d.data.data.typeName;
          this_.equipmentForm.version = d.data.data.version;
          this_.equipmentForm.cellCount = d.data.data.cellCount;
          this_.equipmentForm.temperatureSensorCount = d.data.data.temperatureSensorCount;
          this_.equipmentForm.maxPower = d.data.data.maxPower;
          this_.equipmentForm.capacity = d.data.data.capacity;
          this_.equipmentForm.meterParamtersId = d.data.data.meterParamtersId;
          this_.equipmentForm.templateId = d.data.data.templateId;
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
      this_.dialogTitle = '更新设备';
      this_.equipmentFormShow = true;
      this_.operationType = 2;
    },
    del: function del() {
      var this_ = this;
      var ids = '';

      if (this_.selectedRows.length < 1) {
        this.$message.warning('您还没有选择要删除的设备，请先选择！');
        return;
      } else if (this_.selectedRows.length < 2) {
        ids = this_.selectedRows[0].id;
      } else {
        this_.selectedRows.map(function (x) {
          ids += x.id + ',';
        });
        ids = ids.substring(0, ids.length - 1);
      }

      this_.$confirm({
        title: "删除设备",
        content: '此操作将永久删除选中的设备, 是否继续?',
        okText: '确认',
        cancelText: '取消',
        onOk: function onOk() {
          axios({
            method: 'delete',
            url: '/emind/config/equipment/batch_deletion/' + ids
          }).then(function (d) {
            //
            if (!d) {
              this_.$message.error('登录超时，请重新登录', 2, function () {});
              return;
            }

            if (d.data.code === '0') {
              this_.$message.success('设备删除成功');
              this_.equipTypeList = d.data.data;
              setTimeout(function () {
                this_.cacheEquipments();
                this_.getList();
              }, 100);
            } else {
              this_.$message.error('请求出错', 2, function () {});
            }
          }).catch(function (error) {
            if (error.message != '') {
              this_.$message.error(error.message, 2, function () {});
              return;
            }
          });
        }
      });
    },
    getEquipTypeList: function getEquipTypeList() {
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/config/equipment/type/list'
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.equipTypeList = d.data.data;
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    getParentEquipmentList: function getParentEquipmentList() {
      var this_ = this;
      var data = {
        typeId: this_.search.typeId,
        stationId: localStorage.getItem('stationId')
      };
      axios({
        method: 'post',
        url: '/emind/config/equipment/search_condition',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.parentEquipmentList = d.data.data;
          this_.parentEquipmentList.push({
            id: parseInt(localStorage.getItem('stationId')),
            name: localStorage.getItem('stationName') + '(电站)'
          });
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    //获取刷新列表数据
    getList: function getList() {
      var this_ = this;
      this_.loading = true;
      var data = this_.search;
      data.startIndex = (data.page - 1) * data.length;
      axios({
        method: 'post',
        url: '/emind/config/equipment/search',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        this_.total = d.data.recordsTotal;
        this_.tableData3 = d.data.data;
        var i = 0;
        this_.tableData3.map(function (item) {
          item.key = i++;

          if (item.typeId > 4) {
            item.name = item.num + '#' + item.typeName;
          } else {
            item.name = item.numbering.split('-').length == 2 ? item.name : item.parentEquipmentName + '-' + item.num + '#' + item.typeName;
          }
        });
        this_.loading = false;
      }).catch(function (error) {
        this_.loading = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    searchFn: function searchFn() {
      var this_ = this;
      this_.getList();
    }
  }
};